/*
* demo.css
* File include item demo only specific css only
******************************************************************************/

.light-style .menu .app-brand.demo {
  height: 64px;
}

.dark-style .menu .app-brand.demo {
  height: 64px;
}

.app-brand-logo.demo svg {
  width: 38px;
  height: 20px;
}

.app-brand-text.demo {
  font-size: 1.25rem;
}

/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}

/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

/* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.demo-vertical-spacing > * {
  margin-top: 1.25rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir='rtl'] .rtl-only {
  display: block !important;
}

/* Dropdown buttons going out of small screens */
@media (max-width: 576px) {
  #dropdown-variation-demo .btn-group .text-truncate {
    width: 300px;
    position: relative;
  }
  #dropdown-variation-demo .btn-group .text-truncate::after {
    position: absolute;
    top: 45%;
    right: 0.65rem;
  }
}
/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1.25rem;
}
.layout-demo-placeholder img {
  width: 900px;
}
.layout-demo-info {
  text-align: center;
  margin-top: 1.25rem;
}
.app-brand-logo img { width: 150px; }
.react-datepicker__input-container .form-control { height: 50px;}
.react-datepicker-wrapper {width: 100%; }
.react-datepicker-popper {z-index: 11 !important }
.custm-select-box .css-15k3avv{ z-index:10}
.validate-error { color: #ff0000; }
.error-select .css-vj8t7z { border:2px solid #ff0000; }
.hidden { display: none !important; }
.react-datepicker__time-list-item--disabled {display: none; }
.time-slot-select { height: 50px; }
.custm-select-box, .custm-date-box {position: relative; }
.custm-select-box .select-box-label, .custm-date-box .select-box-label {   top: -18px;
  font-weight: 500;
  transform: scale(0.8);
  background-color: #FFF;
  display: inline-block;
  width: auto;
  margin-left: 13px;
  padding-left: 0;
  padding-bottom: 0px;
  height: auto; }
  .ml-4 {
    margin-left: 1.5rem !important;
}
.pro_current_daydatewise_title, .pro_fullfill_daydatewise_title { background-color: #666cff; color: #FFF; }
.pro_current_daywise_title, .pro_current_datewise_title, .pro_fullfill_daywise_title, .pro_fullfill_datewise_title { background-color: #8488f8; color: #FFF; }
.custm-select-box.filter-select .select-box-label { background: none; }
.template-customizer-open-btn { display: none !important; }
.pro_advancedaytime_availability_div center h4 {
  font-weight: bold;
  font-size: 16px;
}
.pro_daydatewise_availability,
.pro_daywise_availability_main,
.daywiseitem_form_group,
.pro_fullfilldaydatewise_availability,
.pro_fullfilldaywise_availability_main {
  width: 100%;
}

.daywise_avail_chkboxtop {
  margin-right: 2px !important;
}
.daywise_avail_chkboxlabel {
  padding: 8px 0 8px 25px !important;
}
.dayavail_timeslot_label {
  text-align: left !important;
  padding-right: 0px !important;
  width: 10% !important;
}
.dayavail_timeslot_inputbox {
  width: 20% !important;
  padding: 0px 5px;
}
.dayavail_timeslot_inputbox input {
  padding: 6px 6px;
}
.dayavail_timeslot_toinput {
  width: 25% !important;
  padding-right: 0px !important;
  padding-left: 5px;
}
.dayavail_timeslot_toinput .control-label {
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  width: 10%;
}
.dayavail_timeslot_toinput .col-sm-8 {
  padding-right: 0px;
  width: 90% !important;
}
.dayavail_timeslot_stockinput {
  width: 25%;
  padding-right: 0px;
  padding-left: 5px;
}
.dayavail_timeslot_stockinput .control-label {
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  width: 20%;
}
.dayavail_timeslot_stockinput .col-sm-8 {
  padding-right: 0px;
  margin-left: 5px;
  width: 70%;
}
.daywiseitem_text_div,
.fullfilldaywiseitem_text_div {
  background-color: #f7f3f3;
  padding: 10px;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
}
.daywiseitem_form_group .col-sm-11 {
  width: 88%;
  padding-right: 0px !important;
}
.daywiseitem_form_group .col-sm-1 {
  width: 12%;
  padding-right: 0px;
}
.date_availability_time_slot .col-sm-4,
.date_availability_time_slot .col-sm-4,
.fullfilldate_availability_time_slot .col-sm-4,
.fullfilldate_availability_time_slot .col-sm-4 {
  padding-right: 0px;
}
.pro_current_daydatewise_title,
.pro_fullfill_daydatewise_title {
  padding: 10px;
  text-align: center;
  background-color: #d2d0d0;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  float: left;
}
.pro_fullfill_daydatewise_title {
  margin-top: 20px;
}
.pro_current_daywise_title,
.pro_current_datewise_title,
.pro_fullfill_daywise_title,
.pro_fullfill_datewise_title {
  margin: 5px 0px 5px 0px;
  padding: 10px;
  text-align: center;
  background-color: #e4e1e1;
  font-weight: bold;
  font-size: 15px;
}
.dayavail_stockinput_validate {
  padding-left: 0px;
  margin-top: 5px;
}
.dateavail_timeslot_stockinput input {
  padding: 6px 5px !important;
}
.dateavail_timeslot_inputbox {
  width: 28% !important;
}
.dateavail_timeslot_toinput {
  width: 26% !important;
  padding-left: 5px;
}
.dateavail_timeslot_inputbox input,
.dateavail_timeslot_toinput input {
  padding: 6px 5px !important;
}
.date_availability_time_slot .dateavail_timeslot_stockinput,
.fullfilldate_availability_time_slot .dateavail_timeslot_stockinput {
  padding-left: 5px;
  padding-right: 2px;
  width: 11% !important;
}
.error { color: #ff0000; }